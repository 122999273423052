import React from 'react';
import { TextField, Radio, Checkbox, IconButton, Grid, TextFieldProps } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';

type TextFieldPropsExtended = TextFieldProps & { errors: string }

export const TextInputField = (props: TextFieldPropsExtended) => {
    const { onChange, type, errors, onKeyDown } = props;
    const inputType = type === 'number' ? 'text' : type;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof onChange !== 'function') {
            return
        }
        if (type === 'number' && event.target.value) {
            event.target.value = event.target.value.replace(/[^0-9\.]+/g, '');
        }
        onChange(event);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (typeof onKeyDown !== 'function') {
            return
        }
        onKeyDown(event);
    }
    return (
        <div>
            <TextField
                {...props}
                fullWidth
                type={inputType}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <ErrorHelperText error={errors} />
        </div>
    )
}

export const MultilineInputField = (props: any) => {
    const { placeholder, onChange, value, name, variant } = props;
    return (
        <TextField
            multiline
            rows={5}
            fullWidth
            variant={variant}
            margin="normal"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
        />
    )
}

export const RadioButtonField = (props: any) => {
    const { options, onChange, handleDeleteOption, errors } = props;
    return (
        <div>
            {options.map((opt: any, idx: number) => {
                return (
                    <Grid container key={idx}>
                        <Grid item>
                            <Radio disabled style={{ paddingLeft: 0 }} />
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid xs={4} item>
                                    <TextField
                                        name="text"
                                        value={opt.text}
                                        placeholder={`option ${idx+1}`}
                                        onChange={e => onChange(e, idx)}
                                    />
                                    <ErrorHelperText error={errors && errors[idx] && errors[idx].text} />
                                </Grid>
                                <Grid xs={4} item>
                                    <TextField
                                        type="number"
                                        name="score"
                                        value={opt.score}
                                        placeholder="Score"
                                        onChange={e => onChange(e, idx)}
                                    />
                                    <ErrorHelperText error={errors && errors[idx] && errors[idx].score} />
                                </Grid>
                                <Grid xs={4} item>
                                    {options.length > 1
                                        ? (
                                            <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                                                <IconButton onClick={() => handleDeleteOption(idx)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </div>
    )
}

export const CheckboxInputField = (props: any) => {
    const { options, onChange, handleDeleteOption, errors } = props;
    return (
        <div>
            {options.map((opt: any, idx: number) => {
                return (
                    <Grid container key={idx}>
                        <Grid item>
                            <Checkbox disabled style={{ paddingLeft: 0 }} />
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid xs={4} item>
                                    <TextField
                                        name="text"
                                        value={opt.text}
                                        placeholder={`option ${idx+1}`}
                                        onChange={e => onChange(e, idx)}
                                    />
                                    <ErrorHelperText error={errors && errors[idx] && errors[idx].text} />
                                </Grid>
                                <Grid xs={4} item>
                                    <TextField
                                        type="number"
                                        name="score"
                                        value={opt.score}
                                        placeholder="Score"
                                        onChange={e => onChange(e, idx)}
                                    />
                                    <ErrorHelperText error={errors && errors[idx] && errors[idx].score} />
                                </Grid>
                                <Grid xs={4} item>
                                    {options.length > 1
                                        ? (
                                            <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                                                <IconButton onClick={() => handleDeleteOption(idx)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </div>
    )
}

export const DateInputField = (props: any) => {
    const { id, label, disableToolbar, value, error,onChange, format, margin, ...restProps  } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar={disableToolbar || false}
                margin={margin || "normal"}
                id={id || "date-picker-dialog"}
                label={label}
                format={format}
                value={value}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                {...restProps}
            />
            <ErrorHelperText error={error} />
        </MuiPickersUtilsProvider>
    )
}

export const TimeInputField = (props: any) => {
    const { value, onChange } = props;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={value}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
            />
        </MuiPickersUtilsProvider>
    )
}

export const ErrorHelperText = (props: any) => {
    const { error } = props;
    return (
        <div className="error-text">{error}</div>
    );
}

export const ShowRadioOptions = (props: any) => {
    const { options, onChange, values } = props;
    return (
        <div>
            {options.map((opt: any, i: number) => {
                const checked = values.findIndex((val: any) => val.answerId == opt.id);
                return (
                    <div key={i}>
                        <Radio
                            checked={checked !== -1}
                            name="radioBtn"
                            value={opt.id}
                            onChange={onChange}
                        /> {opt.text}
                    </div>
                )
            })}
        </div>
    )
}

export const ShowCheckboxOptions = (props: any) => {
    const { options, onChange, values } = props;
    return (
        <div>
            {options.map((opt: any, i: number) => {
                const checked = values.findIndex((val: any) => val.answerId == opt.id);
                return (
                    <div key={i}>
                        <Checkbox
                            checked={checked !== -1}
                            value={opt.id}
                            onChange={onChange}
                        /> {opt.text}
                    </div>
                )
            })}
        </div>
    )
}
